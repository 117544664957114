import React from "react";
import "./Divider.css";

interface props {
  color?: string;
  className?: string;
}
function Divider({ color, className }: props) {
  return <div className={`divider ${color} ${className}`} />;
}

export default Divider;

import React from "react";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import Button from "../Button/Button";
import ButtonOutline from "../ButtonOutline/ButtonOutline";

interface Props {
  show: boolean;
  title?: string;
  message?: string;
  onClose: () => void;
  onConfirm?: () => void;
}
function ModalConfirmation({
  show,
  onClose,
  onConfirm,
  message = "Dialog message",
  title = "Dialog title",
}: Props) {
  return (
    <SlDialog label={title} open={show} onSlAfterHide={onClose}>
      <p className="mb-4">{message}</p>
      <div className="flex flex-row justify-around">
        <ButtonOutline label="Cancelar" onClick={onClose} />
        <Button label="Confirmar" onClick={onConfirm} />
      </div>
    </SlDialog>
  );
}

export default ModalConfirmation;

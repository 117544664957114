import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectQueuesData } from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import TableList from "../../components/TableList/TableList";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { getQueues } from "../../features/Dashboard/AsyncThunks/colas/getQueues";

function Colas() {
  const queuesData = useSelector(selectQueuesData);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const getQueuesData = async () => {
    setLoading(true);
    await dispatch(getQueues());
    setLoading(false);
  };
  useEffect(() => {
    console.log("queuesData :>> ", queuesData);
  }, [queuesData]);

  useEffect(() => {
    // getQueuesData();
  }, []);

  return (
    <div className="flex flex-col w-full px-8">
      {loading && <PageLoader />}
      <div className="flex flex-row items-center py-4">
        <strong className="px-3">Colas - Estado general</strong>
        <Button
          className="w-40"
          label="Refresh"
          iconName="arrow-clockwise"
          onClick={getQueuesData}
        />
      </div>
      <div className="flex flex-col w-auto">
        <TableList list={queuesData} />
      </div>
    </div>
  );
}

export default Colas;

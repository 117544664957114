import React, { useEffect, useState } from "react";
import { DOTS, usePagination } from "./usePagination";
import "./Pagination.css";

export const defaultPaginationConfig = {
  limit: 10, // Page size
  startOffset: 0,
};

interface Props {
  onPageChange: (data: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage?: number;
  pageSize?: number;
  className?: string;
}

function Pagination(props: Props) {
  const {
    onPageChange,
    totalCount = 10,
    siblingCount = 1,
    currentPage: currentPageProp = 1,
    pageSize = defaultPaginationConfig.limit,
    className = "",
  } = props;
  const [currentPage, setCurrentPage] = useState(currentPageProp);

  useEffect(() => {
    setCurrentPage(currentPageProp);
  }, [currentPageProp]);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (!paginationRange) {
    return <></>;
  }

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const handleChangePage = (newPage: number) => {
    onPageChange(newPage);
    setCurrentPage(newPage);
  };

  const onNext = () => {
    handleChangePage(currentPage + 1);
  };

  const onPrevious = () => {
    handleChangePage(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={`pagination-container flex flex-row py-3 bg-white justify-center ${className}`}
    >
      {/* Left navigation arrow */}
      <li
        className={`pagination-item text-center ${
          currentPage === 1 ? "disabled" : ""
        }`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        // Render our Page Pills
        return (
          <li
            className={`pagination-item text-center ${
              pageNumber === currentPage ? "selected" : ""
            }`}
            onClick={() => handleChangePage(Number(pageNumber))}
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={`pagination-item text-center ${
          currentPage === lastPage ? "disabled" : ""
        }`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
}

export default Pagination;

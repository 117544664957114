import React, { useEffect, useState } from "react";
import Button from "../../../components/reusable/Button/Button";
import TableList from "../../../components/TableList/TableList";
import { useAppDispatch } from "../../../app/store";
import PageLoader from "../../../components/reusable/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import { dashboardAPI } from "../../../Api/dashboardAPI";
import { Alumno } from "../../../types/alumnos";
import { getDetalleDeAlumno } from "../../../features/Dashboard/AsyncThunks/alumnos/getDetalleDeAlumno";
import { getAlumnosRegistrados } from "../../../features/Dashboard/AsyncThunks/alumnos/getAlumnosRegistrados";
import ModalConfirmation from "../../../components/reusable/ModalConfirmation/ModalConfirmation";
import { toastError, toastSuccess } from "../../../utils/toast";
import Pagination from "../../../components/reusable/Pagination/Pagination";
import SlRadio from "@shoelace-style/shoelace/dist/react/radio";
import {
  selectAlumnosRegistrados,
  selectRegistradosCurrentPage,
  setRegistradosCurrentPage,
} from "../../../features/Dashboard/Slices/alumnosSlice";
import BuscadorAlumnosRegistrados from "../../../components/BuscadorAlumnosRegistrados";
interface Props {
  onDetailPressed: () => void;
}
function AlumnosRegistrados({ onDetailPressed }: Props) {
  const [loading, setLoading] = useState(false);
  const alumnosRegistrados = useSelector(selectAlumnosRegistrados);
  const [showModalReset, setShowModalReset] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const registradosCurrentPage = useSelector(selectRegistradosCurrentPage);
  const [alumnoSelected, setAlumnoSelected] = useState<Alumno | undefined>();
  const dispatch = useAppDispatch();

  /* -------------------------------------------------------------------------- */
  /*                               LÓGICA PAGINADO                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAlumnosRegistradosPaginadoInicial();
  }, []);

  const getAlumnosRegistradosPaginadoInicial = async () => {
    await dispatch(setRegistradosCurrentPage(1));
    await getAlumnosRegistradosPaginado();
  };

  const getAlumnosRegistradosPaginado = async () => {
    setLoading(true);
    await dispatch(getAlumnosRegistrados());
    setLoading(false);
  };

  const handleChangePage = async (newPage: number) => {
    await dispatch(setRegistradosCurrentPage(newPage));
    await getAlumnosRegistradosPaginado();
  };
  /* -------------------------------------------------------------------------- */
  /*                               ACCIONES ALUMNO                              */
  /* -------------------------------------------------------------------------- */
  const showModalResetearAlumno = () => {
    setShowModalReset(true);
  };

  const resetearAlumno = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const { id_persona } = alumnoSelected;
        const res = await dashboardAPI.resetearAlumno(id_persona);
        toastSuccess(JSON.stringify(res));
        getAlumnosRegistradosPaginadoInicial();
      } else {
        throw new Error("No se encuentra id_persona");
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    } finally {
      setShowModalReset(false);
    }
    setLoading(false);
  };

  const showModalBorrarAlumno = () => {
    setShowModalDelete(true);
  };

  const borrarAlumno = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const { id_persona } = alumnoSelected;
        const res = await dashboardAPI.eliminarAlumno(id_persona);
        toastSuccess(JSON.stringify(res));
        getAlumnosRegistradosPaginadoInicial();
      } else {
        throw new Error("No se encuentra id_persona");
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    } finally {
      setShowModalDelete(false);
    }
    setLoading(false);
  };

  const verDetalleDeAlumno = async () => {
    if (alumnoSelected) {
      const { id_alumno_carrera } = alumnoSelected;
      await dispatch(getDetalleDeAlumno(id_alumno_carrera));
      onDetailPressed();
    }
  };

  const actualizarOfertas = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.actualizarOferta(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const actualizarInscripciones = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.actualizarInscripciones(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const invalidarSemaforo = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.invalidarSemaforo(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const invalidarOferta = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.invalidarOferta(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };
 
  const alumnoAccionesBotones = [
    {
      action: () => showModalResetearAlumno(),
      label: "Resetear",
      theme: "blue",
    },
    {
      action: () => showModalBorrarAlumno(),
      label: "Borrar",
      theme: "red",
    },
    {
      action: () => verDetalleDeAlumno(),
      label: "Detalle",
      theme: "yellow",
    },
    {
      action: () => actualizarOfertas(),
      label: "Actualizar ofertas",
      theme: "green",
    },
    {
      action: () => actualizarInscripciones(),
      label: "Actualizar inscripciones",
      theme: "blue",
    },
    {
      action: () => invalidarSemaforo(),
      label: "Invalidar semáforo",
      theme: "red",
    },
    {
      action: () => invalidarOferta(),
      label: "Invalidar oferta",
      theme: "red",
    },
  ];

  return (
    <div className="flex justify-center px-5">
      {loading && <PageLoader />}
      <ModalConfirmation
        show={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        onConfirm={borrarAlumno}
        title="Borrar alumno"
        message={`¿Desea borrar el registro del alumno ${alumnoSelected?.alumno} ?`}
      />
      <ModalConfirmation
        show={showModalReset}
        onClose={() => setShowModalReset(false)}
        onConfirm={resetearAlumno}
        title="Resetear alumno"
        message={`¿Desea resetear el registro del alumno ${alumnoSelected?.alumno} ?`}
      />
      <div className="flex flex-col w-full">
        <b className="text-center mt-5">Alumnos registrados</b>
        {alumnoSelected && (
          <div className="py-5 px-5 flex flex-row items-center bg-white rounded-lg w-min">
            <strong className="text-center pl-2 pr-5 text-nowrap">
              Alumno seleccionado{" "}
              <p className="font-normal text-nowrap">
                {alumnoSelected?.alumno}
              </p>
            </strong>
            <div className="flex flex-row">
              {alumnoAccionesBotones.map(({ action, label, theme }: any) => (
                <Button
                  className="self-center ml-3 text-nowrap"
                  onClick={action}
                  label={label}
                  theme={theme}
                />
              ))}
            </div>
          </div>
        )}
        <div className="pt-3 flex flex-col items-center justify-between">
          <BuscadorAlumnosRegistrados />
        </div>
        <div className="h-auto mb-5 pt-4 border-solid">
          <div className="flex flex-row items-center bg-white py-3 rounded-md">
            <Button
              className="ml-4"
              onClick={getAlumnosRegistradosPaginadoInicial}
              label="Actualizar listado"
              iconName="arrow-clockwise"
            />
          </div>
          <TableList
            list={alumnosRegistrados.alumnos}
            customColumns={[
              {
                columnTitle: "Select",
                boxContent: (alumno) => {
                  return (
                    <div className="flex w-20 justify-center">
                      <SlRadio
                        className="pl-2"
                        value="1"
                        checked={
                          alumnoSelected?.id_persona === alumno?.id_persona
                        }
                        onClick={() => setAlumnoSelected(alumno)}
                      />
                    </div>
                  );
                },
              },
            ]}
          />
          <Pagination
            totalCount={alumnosRegistrados.totalCount}
            onPageChange={handleChangePage}
            currentPage={registradosCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AlumnosRegistrados;

import React from "react";
// App.jsx
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import "./App.css";
import "./variables.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Sidebar from "./components/Sidebar/Sidebar";
import Searchbar from "./components/Searchbar/Searchbar";
import EstadoGeneral from "./pages/EstadoGeneral/EstadoGeneral";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Cupos from "./pages/Cupos/Cupos";
import Parametros from "./pages/Parametros/Parametros";
import Alumnos from "./pages/Alumnos/Alumnos";
import Validaciones from "./pages/Validaciones/Validaciones";
import Colas from "./pages/Colas/Colas";
import Servicios from "./pages/Servicios/Servicios";
import Novedades from "./pages/Novedades/Novedades";
import Reservas from "./pages/Reservas/Reservas";
import Eventos from "./pages/Eventos/Eventos";
setBasePath(
  "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/"
);

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-row flex-1 h-full">
        <ToastContainer />
        <Sidebar />
        <div className="pageContent bg-grey">
          <Searchbar />
          <Routes>
            {/* <Route Component={Login} path="/" /> */}
            <Route Component={Home} path="/" />
            <Route Component={EstadoGeneral} path="/estado_general" />
            <Route Component={Cupos} path="/cupos" />
            <Route Component={Colas} path="/colas" />
            <Route Component={Parametros} path="/parametros" />
            <Route Component={Alumnos} path="/alumnos" />
            <Route Component={Validaciones} path="/validaciones" />
            <Route Component={Servicios} path="/services" />
            <Route Component={Novedades} path="/novedades" />
            <Route Component={Reservas} path="/reservas" />
            <Route Component={Eventos} path="/eventos" />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import TableList from "../../components/TableList/TableList";
import { dashboardAPI } from "../../Api/dashboardAPI";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import Pagination, {
  defaultPaginationConfig,
} from "../../components/reusable/Pagination/Pagination";
import { toastError } from "../../utils/toast";
import Button from "../../components/reusable/Button/Button";
import { Novedad, NovedadBitacora } from "../../types/novedad";

function Novedades() {
  const [novedades, setNovedades] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(10);

  const buscarNovedadesOracle = async () => {
    await dashboardAPI.buscarNovedadesOracle();
  };

  const getNewNovedades = async (newPage?: number) => {
    setLoading(true);
    try {
      const newData = await dashboardAPI.getNovedadesPaginated(
        defaultPaginationConfig.limit,
        newPage
          ? (newPage - 1) * defaultPaginationConfig.limit
          : defaultPaginationConfig.startOffset
      );
      if (newPage) {
        setCurrentPage(newPage);
      } else {
        setCurrentPage(1);
      }
      setNovedades(newData.data);
      setTotalRows(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const procesarNovedad = async (novedad: Novedad) => {
    setLoading(true);
    try {
      const res = await dashboardAPI.procesarNovedad(novedad);
      console.log("procesarNovedad - response:", res);
    } catch (error) {
      console.log("error:", error);
    }
    setLoading(false);
  };

  const descartarNovedad = async (novedad: Novedad) => {
    setLoading(true);
    try {
      const res = await dashboardAPI.descartarNovedad(novedad);
      console.log("descartarNovedad - response:", res);
    } catch (error) {
      console.log("error:", error);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    getNewNovedades();
  }, []);

  return (
    <div>
      {loading && <PageLoader />}
      <div className="px-8 py-5">
        <div className="pl-3">
          <strong className="text-lg">Novedades</strong>
          <p className="text-sm">
            En el siguiente listado se muestran las últimas novedades ordenadas
            de manera descendente por su fecha de actualización
          </p>
          <div className="flex flex-row">
          <Button
            className="ml-3"
            label="Actualizar listado"
            onClick={() => getNewNovedades(1)}
          />
          <Button
            className="ml-3"
            label="Buscar novedades en Oracle"
            onClick={() => buscarNovedadesOracle()}
            theme="blue"
          />
          </div>
        </div>
        <div className="flex flex-col pt-3 items-center">
          <div className="w-11/12">
            <TableList
              list={novedades}
              extraColumnButtons={[
                {
                  action: (novedad: NovedadBitacora) =>
                    procesarNovedad(JSON.parse(novedad.datos_evento)),
                  buttonLabel: "Procesar",
                  columnTitle: "Procesar manualmente",
                },
                {
                  action: (novedad: NovedadBitacora) =>
                    descartarNovedad(JSON.parse(novedad.datos_evento)),
                  buttonLabel: "Descartar",
                  columnTitle: "Descartar novedad",
                }
              ]}
            />
          </div>
          <div className="h-32 w-auto rounded-xl">
            <Pagination
              className="w-auto rounded-xl px-5"
              onPageChange={(newPage) => getNewNovedades(newPage)}
              totalCount={totalRows}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Novedades;

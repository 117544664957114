import React, { useEffect, useState } from "react";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import "./InputSearch.css";

interface Props {
  placeholder?: string;
  onChange: (data: string) => void;
  onBlur?: () => void;
  onConfirmSearch?: () => void;
  className?: string | undefined;
  type?:
    | "date"
    | "datetime-local"
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url";
  size?: "small" | "medium" | "large" | undefined;
  disabled?: boolean;
  value?: string;
}
function InputSearch(props: Props) {
  const {
    onChange,
    onConfirmSearch = () => {},
    onBlur,
    placeholder = "",
    className = "",
    type,
    size = "medium",
    disabled,
    value,
  } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onConfirmSearch();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <SlInput
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      value={inputValue}
      onSlInput={(e: any) => {
        onChange(e?.target?.value);
        setInputValue(e?.target?.value);
      }}
      onSubmit={onConfirmSearch}
      onBlur={onBlur}
      size={size}
      pill
    >
      <SlIcon
        className="pr-3 iconButton"
        name="search"
        slot="suffix"
        onClick={onConfirmSearch}
      />
    </SlInput>
  );
}

export default InputSearch;

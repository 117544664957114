import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectServicesVersions } from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import TableList from "../../components/TableList/TableList";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { getServicesVersions } from "../../features/Dashboard/AsyncThunks/servicios/getServicesStatus";

function Servicios() {
  const servicesVersions = useSelector(selectServicesVersions);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const getServicesData = async () => {
    setLoading(true);
    await dispatch(getServicesVersions());
    setLoading(false);
  };

  useEffect(() => {
    getServicesData();
  }, []);

  return (
    <div className="flex flex-1">
      {loading && <PageLoader />}
      <div className="px-5 py-5">
        <div className="flex flex-row items-center">
          <strong className="px-2 my-4 text-base">
            Versiones de servicios
          </strong>
          <Button
            className="h-9"
            label="Actualizar"
            iconName="arrow-clockwise"
            onClick={getServicesData}
          />
        </div>
        <TableList list={servicesVersions} />
      </div>
    </div>
  );
}

export default Servicios;

import React, { useEffect, useRef, useState } from "react";
import SlInput from "@shoelace-style/shoelace/dist/react/input";

interface Props {
  placeholder?: string;
  onChange: (data: string) => void;
  onBlur?: () => void;
  className?: string | undefined;
  type?:
    | "date"
    | "datetime-local"
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url";
  size?: "small" | "medium" | "large" | undefined;
  defaultValue?: string;
  disabled?: boolean;
}
function Input(props: Props) {
  const {
    onChange,
    onBlur,
    placeholder = "",
    className = "",
    type,
    size = "medium",
    defaultValue,
    disabled,
  } = props;
  const ref: any = useRef(null);
  const [value, setValue] = useState(defaultValue || "");

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (text: string) => {
    onChange(text);
    setValue(text);
  };

  return (
    <SlInput
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      value={value}
      type={type}
      ref={ref}
      onSlInput={(e: any) => handleChange(e?.target?.value)}
      onBlur={onBlur}
      size={size}
    />
  );
}

export default Input;

import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import dashboardSlice from "../features/Dashboard/Slices/dashboardSlice";
import alumnosSlice from "../features/Dashboard/Slices/alumnosSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    alumnos: alumnosSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;

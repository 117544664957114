import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { EstadoTablasMirrorResponse } from "../../../interfaces/dashboardTypes";
import { getParametrosBuilder } from "../AsyncThunks/parametros/getParametros";
import {
  getValidacionesBuilder,
  Validacion,
} from "../AsyncThunks/validaciones/getValidaciones";
import { getEstadoTablasMirrorBuilder } from "../AsyncThunks/estadoGeneral/getEstadoTablasMirror";
import { getDetalleDeCupoBuilder } from "../AsyncThunks/cupos/getDetalleDeCupo";
import { getQueuesBuilder } from "../AsyncThunks/colas/getQueues";
import { getServicesVersionsBuilder } from "../AsyncThunks/servicios/getServicesStatus";
import { getDetalleCuadraturaDeCupoBuilder } from "../AsyncThunks/cupos/getDetalleCuadratura";

export interface DashboardInitState {
  loading: boolean;
  sideBarOpen: boolean;
  estadoTablasMirror: EstadoTablasMirrorResponse;
  detalleCupo: any;
  detalleCuadraturaCupos: [];
  reservasEnTransito: any[];
  desinscripcionesEnTransito: any[];
  parametros: { clave: string; valor: string; descripcion: string }[];
  validaciones: Validacion[];
  queuesData: any[];
  servicesVersions: { name: string; version: string; environment: string }[];
}
const initState: DashboardInitState = {
  loading: false,
  sideBarOpen: true,
  estadoTablasMirror: [],
  detalleCupo: {},
  detalleCuadraturaCupos: [],
  reservasEnTransito: [],
  desinscripcionesEnTransito: [],
  parametros: [],
  validaciones: [],
  queuesData: [],
  servicesVersions: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initState,
  reducers: {
    toggleSidebar: (state) => {
      state.sideBarOpen = !state.sideBarOpen;
    },
  },
  extraReducers: (builder) => {
    getEstadoTablasMirrorBuilder(builder);
    getParametrosBuilder(builder);
    getDetalleDeCupoBuilder(builder);
    getValidacionesBuilder(builder);
    getQueuesBuilder(builder);
    getServicesVersionsBuilder(builder);
    getDetalleCuadraturaDeCupoBuilder(builder);
  },
});

/* -------------------------------------------------------------------------- */
/*                                    CUPOS                                   */
/* -------------------------------------------------------------------------- */
export const selectDetalleCupo = (state: RootState) =>
  state.dashboard.detalleCupo;

export const selectCuadraturaCupos = (state: RootState) =>
  state.dashboard.detalleCuadraturaCupos;
/* -------------------------------------------------------------------------- */
/*                                INSCRIPCIONES                               */
/* -------------------------------------------------------------------------- */
export const selectDesinscripcionesEnTransito = (state: RootState) =>
  state.dashboard.desinscripcionesEnTransito;
export const selectReservasEnTransito = (state: RootState) =>
  state.dashboard.reservasEnTransito;
/* -------------------------------------------------------------------------- */
/*                                 PARAMETROS                                 */
/* -------------------------------------------------------------------------- */
export const selectParametros = (state: RootState) =>
  state.dashboard.parametros;

export const selectEstadoDelSistema = (state: RootState) =>
  state.dashboard.parametros.find(({ clave }) => clave === "ESTADO_SISTEMA")
    ?.valor;

export const selectValidaciones = (state: RootState) =>
  state.dashboard.validaciones;
/* -------------------------------------------------------------------------- */
/*                                   QUEUES                                   */
/* -------------------------------------------------------------------------- */
export const selectQueuesData = (state: RootState) =>
  state.dashboard.queuesData;
/* -------------------------------------------------------------------------- */
/*                                   STATES                                   */
/* -------------------------------------------------------------------------- */
export const selectServicesVersions = (state: RootState) =>
  state.dashboard.servicesVersions;

export const selectEstadoTablasMirror = (state: RootState) =>
  state.dashboard.estadoTablasMirror;
/* -------------------------------------------------------------------------- */
/*                                   GENERAL                                  */
/* -------------------------------------------------------------------------- */
export const selectLoading = (state: RootState) => state.dashboard.loading;

export const selectSidebarOpen = (state: RootState) =>
  state.dashboard.sideBarOpen;

export const { toggleSidebar } = dashboardSlice.actions;

export default dashboardSlice.reducer;

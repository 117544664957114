import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";


export const postActualizarCupos = createAsyncThunk(
  "dashboard/actualizarCupos",
  async (_, thunkAPI) => {
    try {
      console.log("Actualizando estado de tablas mirror...");
      const response = await dashboardAPI.actualizarCupos();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getEstadoTablasMirror = createAsyncThunk(
  "dashboard/estadoTablasMirror",
  async (_, thunkAPI) => {
    try {
      console.log("Actualizando estado de tablas mirror...");
      const response = await dashboardAPI.estadoTablasMirror();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getEstadoTablasMirrorBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getEstadoTablasMirror.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getEstadoTablasMirror.fulfilled, (state, action) => {
    console.log("getEstadoTablasMirror fulfilled :>> ", action);
    state.loading = false;
    state.estadoTablasMirror = action.payload;
  });
  builder.addCase(getEstadoTablasMirror.rejected, (state, action) => {
    console.log("getEstadoTablasMirror rejected :>> ", action);
    state.loading = false;
  });
};

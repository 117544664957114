import React from "react";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
interface Props {
  variant?:
    | "default"
    | "primary"
    | "success"
    | "neutral"
    | "warning"
    | "danger"
    | "text"
    | undefined;
  label?: string;
  onClick?: () => void;
  className?: string | undefined;
}
function ButtonOutline({ variant, className, label, onClick }: Props) {
  return (
    <SlButton variant={variant} outline className={className} onClick={onClick}>
      {label}
    </SlButton>
  );
}

export default ButtonOutline;

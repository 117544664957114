import axios from "axios";
import { conf } from "../utils/config";
import { Novedad } from "../types/novedad";

const backNotificationAxios = axios.create({
  baseURL: conf("BACKEND_NOTIFICATION_URL"),
});

const backProxyAxios = axios.create({
  baseURL: conf("BACKEND_PROXY_URL"),
});

const backSyncAxios = axios.create({
  baseURL: conf("BACKEND_SYNC_URL"),
});

const backSyncAxios1 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_1"),
});

const backSyncAxios2 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_2"),
});

const backSyncAxios3 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_3"),
});

const backSyncAxios4 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_4"),
});

const backSyncAxios5 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_5"),
});

const backSyncAxios6 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_6"),
});

const backSyncAxios7 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_7"),
});

const backSyncAxios8 = axios.create({
  baseURL: conf("BACKEND_SYNC_URL_8"),
});

const backAPIAxios1 = axios.create({
  baseURL: conf("BACKEND_API_URL_1"),
});

const backAPIAxios2 = axios.create({
  baseURL: conf("BACKEND_API_URL_2"),
});

const backAPIAxios3 = axios.create({
  baseURL: conf("BACKEND_API_URL_3"),
});

const backAPIAxios4 = axios.create({
  baseURL: conf("BACKEND_API_URL_4"),
});

const backAPIAxios5 = axios.create({
  baseURL: conf("BACKEND_API_URL_5"),
});

const backAPIAxios6 = axios.create({
  baseURL: conf("BACKEND_API_URL_6"),
});

const backAPIAxios7 = axios.create({
  baseURL: conf("BACKEND_API_URL_7"),
});

const backAPIAxios8 = axios.create({
  baseURL: conf("BACKEND_API_URL_8"),
});

export const dashboardAPI = {
  syncronizarAlumnos: async (identificacion: number | null, programa: number | null, orientacion: number | null) => {
    const response: any = await axios.post(
      `/dashboard/syncronizarAlumnos`,
      {
        identificacion: identificacion,
        programa: programa,
        orientacion: orientacion,
      }
    );
    return response.data;
  },
  sincronizarSiguienteTabla: async () => {
    const response: any = await axios.post(
      `/dashboard/inicializarSiguienteTabla`,
      {}
    );
    return response.data;
  },
  inicializarConfiguracion: async () => {
    const response: any = await axios.post(`/dashboard/resetConfiguracion`, {});
    return response.data;
  },
  borrarTodosLosAlumnos: async () => {
    const response: any = await axios.post(
      `/dashboard/borrarTodosLosAlumnos`,
      {}
    );
    return response.data;
  },
  alumnosInicializados: async () => {
    const response: any = await axios.post(
      `/dashboard/alumnosInicializados`,
      {}
    );
    return response.data;
  },
  inicializarAlumno: async (email: string) => {
    const response: any = await axios.post(`/dashboard/inicializarAlumno`, {
      email,
    });
    return response.data;
  },
  obtenerDetalleAlumno: async (idAlumnoPrograma: number) => {
    const response: any = await axios.post(`/dashboard/detalleAlumno`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  inicializarTablasMirror: async () => {
    const response: any = await axios.post(
      `/dashboard/inicializarTablasMirror`,
      {}
    );
    return response.data;
  },
  inicializarTablaMirror: async (tabla: string) => {
    const response: any = await axios.post(
      `/dashboard/inicializarTablasMirror`,
      { tabla }
    );
    return response.data;
  },
  resetInfoAlumnos: async () => {
    const response: any = await axios.post(`/dashboard/resetInfoAlumnos`, {});
    return response.data;
  },
  deleteAllTables: async () => {
    const response: any = await axios.post(`/dashboard/deleteAllTables`, {});
    return response.data;
  },
  dropAllTables: async () => {
    const response: any = await axios.post(`/dashboard/dropAllTables`, {});
    return response.data;
  },
  actualizarCupos: async () => {
    const response = await axios.post(`/dashboard/actualizarTodosLosCupos`, {});
    return response.data;
  },
  actualizarCupo: async (idCupo: number) => {
    const response = await axios.post(`/dashboard/actualizarCupo`, { idCupo });
    return response.data;
  },
  syncCuposDescuadrados: async () => {
    const response = await axios.post(`/dashboard/syncCuposDescuadrados`, {});
    return response.data;
  },
  estadoTablasMirror: async () => {
    const response = await axios.post(`/dashboard/estadoTablasMirror`, {});
    return response.data;
  },
  getDetalleCuadratura: async () => {
    const response = await axios.post(`/dashboard/getDetalleCuadratura`, { });
    return response.data;
  },
  obtenerDetalleCupo: async (idCupo: number) => {
    const response = await axios.post(`/dashboard/detalleCupo`, {
      idCupo,
    });
    return response.data;
  },
  resetearAlumno: async (idPersona: number) => {
    const response = await axios.post(`/dashboard/resetearAlumno`, {
      idPersona,
    });
    return response.data;
  },
  eliminarAlumno: async (idPersona: number) => {
    const response = await axios.post(`/dashboard/eliminarAlumno`, {
      idPersona,
    });
    return response.data;
  },
  invalidarSemaforo: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/invalidarSemaforo`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  invalidarOferta: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/invalidarOferta`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  actualizarOferta: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/actualizarOferta`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  actualizarInscripciones: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/actualizarInscripciones`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  obtenerAlumnosRegistrados: async () => {
    const response = await axios.post(`/dashboard/alumnosRegistrados`, {});
    return response.data;
  },
  obtenerAlumnosRegistradosPaginado: async (
    limit: number,
    offset: number,
    carrera?: string,
    searchText?: string
  ) => {
    const response = await axios.post(`/dashboard/alumnosRegistradosPag`, {
      limit,
      offset,
      carrera: carrera || "",
      searchText: searchText || "",
    });
    return response.data;
  },
  buscarNovedadesOracle: async () => {
    const response = await axios.get(`/dashboard/buscarNovedadesOracle`);
    return response.data;
  },
  obtenerReservasEnTransito: async () => {
    const response = await axios.get(`/dashboard/reservasEnTransito`);
    return response.data;
  },
  obtenerDesinscripcionEnTransito: async () => {
    const response = await axios.get(`/dashboard/desinscripcionesEnTransito`);
    return response.data;
  },
  obtenerParametros: async () => {
    const response = await axios.get(`/dashboard/parametros`);
    return response.data;
  },
  updateParametro: async (parametro: string, value: string) => {
    const response = await axios.put(`/dashboard/parametro`, {
      parametro,
      value,
    });
    return response.data;
  },
  obtenerValidaciones: async () => {
    const response = await axios.get(`/dashboard/validaciones`);
    return response.data;
  },
  inicializarValidaciones: async () => {
    const response = await axios.get(`/dashboard/inicializarValidaciones`);
    return response.data;
  },
  updateValidacion: async (
    activa: string | null,
    configuracion: string | null,
    id: string
  ) => {
    const response = await axios.put(`/dashboard/validacion`, {
      activa,
      configuracion,
      id,
    });
    return response.data;
  },
  obtenerDetallesDeColas: async () => {
    const response = await axios.get(`/dashboard/queues`);
    return response.data;
  },
  logoutGeneral: async () => {
    const response = await axios.get(`/dashboard/logoutGeneral`);
    return response.data;
  },
  getVersionBackApi: async (clon: number | null) => {
    if (clon) {
      let response: any;
      switch (clon) {
        case 1:
          response = await backAPIAxios1.get(`/version`);
          break;
        case 2:
          response = await backAPIAxios2.get(`/version`);
          break;
        case 3:
          response = await backAPIAxios3.get(`/version`);
          break;
        case 4:
          response = await backAPIAxios4.get(`/version`);
          break;
        case 5:
          response = await backAPIAxios5.get(`/version`);
          break;
        case 6:
          response = await backAPIAxios6.get(`/version`);
          break;
        case 7:
          response = await backAPIAxios7.get(`/version`);
          break;
        case 8:
          response = await backAPIAxios8.get(`/version`);
          break;
      }
      return response.data;
    } else {
      const response = await axios.get(`/version`);
      return response.data;
    }
  },
  getVersionBackSync: async (clon: number | null) => {
    if (clon) {
      let response: any;
      switch (clon) {
        case 1:
          response = await backSyncAxios1.get(`/version`);
          break;
        case 2:
          response = await backSyncAxios2.get(`/version`);
          break;
        case 3:
          response = await backSyncAxios3.get(`/version`);
          break;
        case 4:
          response = await backSyncAxios4.get(`/version`);
          break;
        case 5:
          response = await backSyncAxios5.get(`/version`);
          break;
        case 6:
          response = await backSyncAxios6.get(`/version`);
          break;
        case 7:
          response = await backSyncAxios7.get(`/version`);
          break;
        case 8:
          response = await backSyncAxios8.get(`/version`);
          break;
      }
      return response.data;
    } else {
      const response = await backSyncAxios.get(`/version`);
      return response.data;
    }
  },
  getVersionBackNotification: async () => {
    const response = await backNotificationAxios.get(`/version`);
    return response.data;
  },
  getVersionBackProxy: async () => {
    const response = await backProxyAxios.get(`/version`);
    return response.data;
  },
  getCarreras: async () => {
    const response = await axios.get(`/dashboard/carrerasList`);
    return response.data;
  },
  getNovedadesPaginated: async (limit: number, offset: number) => {
    const response = await axios.post(`/dashboard/novedades`, {
      limit,
      offset,
    });
    return response.data;
  },
  descartarNovedad: async (novedad: Novedad) => {
    const response = await axios.post(`/descartarNovedad`, {
      novedad: novedad
    });
    return response.data;
  },
  procesarNovedad: async (novedad: Novedad) => {
    const response = await axios.post(`/novedad`, {
      novedad: novedad,
      manual: true,
    });
    return response.data;
  },
  getTransaccionEnTransito: async (limit: number, offset: number) => {
    const response = await axios.post(`/dashboard/transaccionesEnTransito`, {
      limit,
      offset,
    });
    return response.data;
  },
  procesarTransaccionesPorLote: async (idAlumnoProgramaList: number[]) => {
    const response = await axios.post(
      `/dashboard/inscribirDesinscribirPorLote`,
      { idAlumnoProgramaList }
    );
    return response.data;
  },
  delEventos: async () => {
    const response = await axios.post(`/dashboard/borrarEventos`, {})
    return response.data;
  },
  getEventosPaginated: async (limit: number, offset: number) => {
    const response = await axios.post(`/dashboard/eventos`, {
      limit,
      offset,
    });
    return response.data;
  },
};

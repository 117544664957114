import React from "react";
import "./Searchbar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSidebarOpen,
  toggleSidebar,
} from "../../features/Dashboard/Slices/dashboardSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

function Searchbar() {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(selectSidebarOpen);

  const toggleLeftBar = async () => {
    await dispatch(toggleSidebar());
  };

  return (
    <div className="searchBar">
      <button onClick={toggleLeftBar}>
        <SlIcon
          name={`${sidebarOpen ? "text-indent-right" : "text-indent-left"}`}
          className="text-primary toggleIcon"
        />
      </button>
    </div>
  );
}

export default Searchbar;

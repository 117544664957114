import React, { useState } from "react";
import SlOption from "@shoelace-style/shoelace/dist/react/option";
import SlSelect from "@shoelace-style/shoelace/dist/react/select";
import JsonView from "@uiw/react-json-view";
import { githubDarkTheme } from "@uiw/react-json-view/githubDark";
import { darkTheme } from "@uiw/react-json-view/dark";
import { githubLightTheme } from "@uiw/react-json-view/githubLight";
import { lightTheme } from "@uiw/react-json-view/light";
import { nordTheme } from "@uiw/react-json-view/nord";
import { monokaiTheme } from "@uiw/react-json-view/monokai";
import "./JsonViewer.css";

export type JsonViewerThemes =
  | "ghdark"
  | "dark"
  | "ghlight"
  | "light"
  | "nord"
  | "monokai";

interface Props {
  json?: {};
  title?: string;
  collapsed?: boolean;
  className?: string;
}

function JsonViewer({
  json = {},
  title = "",
  collapsed = true,
  className = "",
}: Props) {
  const [codeTheme, setCodeTheme] = useState<JsonViewerThemes>("ghdark");

  const getTheme = () => {
    switch (codeTheme) {
      case "ghdark":
        return githubDarkTheme;
      case "dark":
        return darkTheme;
      case "ghlight":
        return githubLightTheme;
      case "light":
        return lightTheme;
      case "nord":
        return nordTheme;
      case "monokai":
        return monokaiTheme;
      default:
        break;
    }
  };
  return (
    <div className={`bg-white jsonViewerContainer ${className}`}>
      <div className="pb-1">
        <SlSelect
          label="Tema seleccionado"
          className="font-light text-sm"
          defaultValue={"ghdark"}
          value={codeTheme}
          onSlChange={(e: any) => setCodeTheme(e?.target?.value)}
        >
          <SlOption value="ghdark">Github Dark</SlOption>
          <SlOption value="dark">Dark</SlOption>
          <SlOption value="ghlight">Github light</SlOption>
          <SlOption value="light">Light</SlOption>
          <SlOption value="nord">Nord</SlOption>
          <SlOption value="monokai">Monokai</SlOption>
        </SlSelect>
      </div>
      {title.length > 0 && <b className="pt-2 pb-1">{title}</b>}
      <JsonView
        value={json}
        style={getTheme()}
        highlightUpdates={false}
        displayDataTypes={false}
        collapsed={collapsed}
      />
    </div>
  );
}

export default JsonViewer;

import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import Logo from "../../assets/ditella-logo.png";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../../features/Dashboard/Slices/dashboardSlice";
import { conf } from "../../utils/config";
var pjson = require("../../../package.json");
console.log(pjson.version);

const entorno = conf("ENV");
const version = pjson.version;

function Sidebar() {
  const sidebarOpen = useSelector(selectSidebarOpen);
  const location = useLocation();

  useEffect(() => {
    console.log("location :>> ", location.pathname);
  }, [location]);

  const navigate = useNavigate();
  const buttons = [
    {
      icon: "house",
      label: "Home",
      route: "",
      active: location.pathname === "/",
    },
    {
      icon: "clipboard-data",
      label: "Estado General",
      route: "estado_general",
      active: location.pathname === "/estado_general",
    },
    {
      icon: "ticket-perforated",
      label: "Cupos",
      route: "cupos",
      active: location.pathname === "/cupos",
    },
    {
      icon: "sliders2",
      label: "Parámetros",
      route: "parametros",
      active: location.pathname === "/parametros",
    },
    {
      icon: "person-circle",
      label: "Alumnos",
      route: "alumnos",
      active: location.pathname === "/alumnos",
    },
    {
      icon: "person-vcard",
      label: "Validaciones",
      route: "validaciones",
      active: location.pathname === "/validaciones",
    },
    /* {
      icon: "envelope",
      label: "Colas",
      route: "colas",
      active: location.pathname === "/colas",
    }, */
    {
      icon: "hdd-stack",
      label: "Estado Servicios",
      route: "services",
      active: location.pathname === "/services",
    },
    {
      icon: "newspaper",
      label: "Novedades",
      route: "novedades",
      active: location.pathname === "/novedades",
    },
    {
      icon: "journal-text",
      label: "TX Fallidas",
      route: "reservas",
      active: location.pathname === "/reservas",
    },
    {
      icon: "hdd-network",
      label: "Eventos",
      route: "eventos",
      active: location.pathname === "/eventos",
    },
  ];

  return (
    <>
      <div
        className={`${
          sidebarOpen ? "sidebar active" : "sidebar"
        } flex-col bg-white`}
      >
        <img src={Logo} className="logo mt-4" />
        <div className="entorno mt-2">
          {entorno}-v{version}
        </div>
        <div className="buttonsContainer overflow-auto">
          {buttons.map(({ label, icon, route, active }) => {
            return (
              <button
                className={`linkButton ${active ? "active" : ""} mt-2 mb-2`}
                onClick={() => navigate(route)}
              >
                <h1 className="pl-3 flex flex-row items-center">
                  <SlIcon name={icon} className="sidebar-icon" />
                  {label}
                </h1>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
